// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import moment from 'moment';
//import { Sortable } from '@shopify/draggable';
//import Draggable, {DraggableCore} from 'react-draggable'; // Both at the same time
import Loading from './Loading';
import DateRange from './DateRange';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { capitalizeFLetter, ajaxPerso, findWherePosted, fetchMenu, menuOrganized } from '../../fnc'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronCircleDown, faChevronCircleUp, faTrash, faHistory, faTrashRestoreAlt, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

const refConst = require("../../constants.js").v
const specialTags = [
	{
		name: "contactsUsForPrice",
		description: "Replace buy button by a contact form."
	},
	{
		name: "hidePrice",
		description: "Show the price only in cart"
	},
	{
		name: "hideAPS",
		description: "Hide the a+ content"
	}
]

/* refConst.styleList.container = (provided) => ({
	...provided,
	width: "80%",
	minWidth: "250px"
})
refConst.styleList.control = provided => ({
	...provided,
	borderTopLeftRadius: "5px",
	borderBottomLeftRadius: "5px"
}) */

export default function UpdateShopifyTags(props){

	const initialState = {
		// itemReadyForStoreSelected: {storeFrom: null, storeTo: null, items: []},
		itemsValid: [],
		items: [],
		allTags: [],
		itemsCreated: {}, // {sku: {handle: '', id: ''}}
		submited: false,
		selectedToDel: null,
		required: ['Category_', 'Brand_']
	}
	const [required, setRequired] = useState(initialState.required);
	const [selectedToDel, setSelectedToDel] = useState(initialState.selectedToDel);
	const [submited, setSubmited] = useState(initialState.submited);
	const [itemsCreated, setItemsCreated] = useState(Object.assign({}, initialState.itemsCreated));
	const [itemsValid, setItemsValid] = useState(initialState.itemsValid.slice(0));
	const [items, setItems] = useState(initialState.items.slice(0));
	const [allTags, setAllTags] = useState(initialState.allTags.slice(0));
	const [version, setVersion] = useState("0.9.0");
	const [timerSectionOpen, setTimerSectionOpen] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [selectedTagGroup, setSelectedTagGroup] = useState(null);
	const [selectedStore, setSelectedStore] = useState(null); // For the category option
	const [categories, setCategories] = useState([]);
	const [appyTagToItems, setAppyTagToItems] = useState(true);
	const [showTimedTags, setShowTimedTags] = useState(false);
	const [timedTags, setTimedTags] = useState(null);
	const [tagGroups, setTagGroups] = useState([]);
	const [refreshView, setRefreshView] = useState(0);

	const [dateFrom, setDateFrom] = useState(null);
	const [dateTo, setDateTo] = useState();

	const inputAdd = useRef()
	const activateInterval = true

	/* useEffect(() => {
		console.info('DEBUG', [props.store, props.itemsSelected]);

		if(!props.itemsSelected || props.itemsSelected.length === 0){
			setAppyTagToItems(false)
		}
		
		if(props.store && props.itemsSelected.length > 0){
			init();
			findValidItemsForShop(iL => {
				getShopifyitems(props.store, iL);
			})
		}
		modaleCreateItem(false);
		fetchTimedTags()
	}, [props.store, props.itemsSelected]) */

	useEffect(() => {
		return () => {
			if(window.intervalTagGestion)
				clearInterval(window.intervalTagGestion)
		}
	}, [])

	useEffect(() => {
		modaleCreateItem(true);
	}, [props.store, itemsValid, items, submited, itemsCreated, allTags, selectedToDel, dateFrom, dateTo, appyTagToItems, categories, selectedCategory, timedTags, showTimedTags, selectedStore, timerSectionOpen, tagGroups, selectedTagGroup])

	useEffect(() => {
		setSelectedCategory(null)
		if(selectedStore){
			window.selectedStore = selectedStore
			fetchMenu({
				"shop": selectedStore
			}, () => {
				props.loading(true)
			}, (r) => {
				console.log("Fetched menu", r);
				props.loading(false)
				if(r.success){
					let results = r.data.filter(o => {
						return o
						if(o.is_menu)	return o
						return false
					})
					let menu = menuOrganized(results)
					console.info("menumenumenumenu", menu);
					setCategories(menu)
				}
			});
			fetchTagsGrp()
			fetchTimedTags()
			if(props.itemsSelected.length > 0){
				findValidItemsForShop(iL => {
					console.info("selectedStoreselectedStore", selectedStore);
					console.info("selectedStoreselectedStore2", iL);
					getShopifyitems(selectedStore, iL);
				})
			}
		}
	}, [selectedStore])

	/* useEffect(() => {
		// Selec tthe store by default
		if(!selectedStore)
			setSelectedStore(refConst.stores.find(o => {
				if(o.id_marketplace === 14)
					return o
				return false
			}).id)
	}, [selectedStore]) */

	useEffect(() => {
		let nL = [];
		console.info('items', items);
		
		items.forEach(o => {
			o.tags.forEach(t => {
				if(nL.indexOf(t) === -1)	nL.push(t)
			})
		})
		nL.sort();
		setAllTags(nL);
	}, [items])

	let resetPage = () => {
		for (const key in initialState) {
			if (initialState.hasOwnProperty(key)) {
				const element = initialState[key];
				let val = JSON.parse(JSON.stringify(initialState[key]))
				console.info('Eval result', eval("set" + capitalizeFLetter(key))(val));
			}
		}
	}

	let refreshDbData = () => {
		fetchTimedTags()
		if(activateInterval)
			window.intervalTagGestion = setInterval(() => {
				fetchTimedTags()
			}, 5000)
	}
	
	let triggerClick = () => {

		if(window.intervalTagGestion)
			clearInterval(window.intervalTagGestion)

		if(!props.itemsSelected || props.itemsSelected.length === 0){
			setAppyTagToItems(false)
		}else{
			setAppyTagToItems(true)
		}

		console.info('DEBUG', [props.store, selectedStore]);
		if(props.store && selectedStore != props.store){
			console.info("Changed_store", props.store);
			setSelectedStore(props.store);
		}
		
		modaleCreateItem(false);
	}

	let findValidItemsForShop = (callback) => {
		console.info("sdasdasd", props.itemsSelected);
		let itemsSelected = props.itemsSelected
		if(itemsSelected.length === 0)
			return false

		findWherePosted({
			"idList": itemsSelected.join(',')
		}, (r) => {
			console.info('Fetched_presence:', r);
			if(r.success){
				let iV = [];
				r.data.forEach( o => {
					if(o.list_id_store.indexOf(selectedStore) !== -1)	iV.push(o.id)
				})
				console.info("iViV", iV);
				callback(iV)
			}
		})
	}

	let filtered = () => {

		if(items.length === 0)
			return []

		return items;
	}

	let triggerAddtag = (e) => {
		e.preventDefault()
		
		let val = inputAdd.current.value.trim()
		addTagsForAll(val)
		e.currentTarget.reset()
	}

	const fetchTagsGrp = (callback) => {
		props.loading(true)
		ajaxPerso( {
			"api": "shopify",
			"trigger": "fetchTagsGroup",
			"shop": selectedStore
		}, (r) => {
			console.info(`Fetched_fetchTagsGroup:`, r)
			props.loading(false)
			setTagGroups(r.success? r.res : [])
		})
	}

	let saveTagTimer = (tag, idPrdShopifyList, idCategory) => {

		if(!tag){
			props.info({error: "Please indicate a tag."})
			return false
		}else if(!idPrdShopifyList && !idCategory){
			props.info({error: "Please indicate a list of variant or a category."})
			return false
		}

		let argsToAdd = {}
		if(appyTagToItems)
			argsToAdd.id_product_shopify_list = idPrdShopifyList.join(",")
		else
			argsToAdd.id_category = idCategory

		ajaxPerso( Object.assign({
			"api": "shopify",
			"trigger": "alter_tag_automator",
			"id_marketplace": selectedStore,
			"starting_date": dateFrom,
			"ending_date": dateTo,
			"tag": tag,
			"uid": props.getUser().uid,
			"instant_execute": dateFrom && dateTo? 0 : 1
		}, argsToAdd), (r) => {
			console.info("Result_alter_tag_automator", r);
			if(r.res.processed){
				props.info({success: "Saved"})
				inputAdd.current.value = ""
				setDateFrom(null)
				setDateTo(null)
			}else{
				props.info({error: "Already Saved"})
			}
		});
	}

	let fetchTimedTags = () => {

		if(!window.selectedStore){
			setTimedTags([])
			return false
		}

		ajaxPerso( Object.assign({
			"api": "shopify",
			"trigger": "get_tag_autocreator",
			"id_marketplace": window.selectedStore,
			"uid": props.getUser().uid,
			"special": "special_get_tag",
			// "ended": 0
		}), (r) => {
			console.info("Result_get_tag_autocreator", r);
			setTimedTags(r.success? r.data : [])
		});
	}

	let addTagsForAll = (tag) => {

		let isTimed = dateFrom && dateTo? true : false

		if(!appyTagToItems || isTimed){
			let idVariantList = []
			items.forEach(o => {
				idVariantList.push(o.id_product_shopify)
			})
			saveTagTimer(tag, idVariantList, selectedCategory?.value)
		}else{
			items.forEach(o => {
				if(o.tags.indexOf(tag) === -1){
					o.tags.push(tag)
				}
			})
			setItems(items.slice(0))
			props.info({success: "You can now check if the items are correct and validate to execute the changes"})
		}
	}

	let triggerDeltag = (e) => {
		e.preventDefault()
		let nbrMatching = 0;
		if(selectedToDel)
			items.forEach((o, i) => {
				let pos = o.tags.indexOf(selectedToDel)
				if(pos !== -1){
					o.tags.splice(pos, 1)
					nbrMatching++
				}
			})

		e.currentTarget.reset()
		props.info({success: `Products matching this filter ready ${nbrMatching}/${items.length}`})
		setSelectedToDel(initialState.selectedToDel)
		setItems(items.slice(0))
	}

	let removeTimedTag = (tagData) => {

		ajaxPerso( Object.assign({
			"api": "shopify",
			"trigger": "remove_tag_automator",
			"uid": props.getUser().uid,
			"id": tagData.id
		}), (r) => {
			console.info("Result_remove_tag_automator", r);
			if(r.status){
				props.info({success: "success"})
			}
		});
	}

	let restoreTag = (tagData) => {

		ajaxPerso( Object.assign({
			"api": "shopify",
			"trigger": "restore_tag_automator",
			"uid": props.getUser().uid,
			"id": tagData.id
		}), (r) => {
			console.info("Result_restore_tag_automator", r);
			if(r.status){
				props.info({success: "success"})
			}
		});
	}

	let modaleCreateItem = (refresh) => {
		let isTimed = dateFrom && dateTo? true : false
		props.modal({
			show: refresh? false : true,
			title: "Shopify Tag Manager",
			options: {
				width: "modal-xl"
			},
			html: (popup) => {
				console.info('Item ready for that store', items);

				let objPreSelected = refConst.shopList.find(obj => {
					if(obj.storeId === selectedStore)
						return obj
				})
				
				console.info('itemsCreated', itemsCreated);

				let storeInfo = refConst.stores.find(o => {
					if(o.id === selectedStore)
						return o
					return false
				})
				
				return props.store === null? <Loading loading={true} centered={true}/> : <div>
					<div className="container btn-secondary p-3 border mb-4 position-relative rounded">
					<div className="input-group d-flex flex-row flex-nowrap text-dark justify-content-center">
						<div className="input-group-prepend">
							<span className="input-group-text">Store</span>
						</div>
						<Select
							styles={Object.assign(refConst.styleList, {
								container: (provided) => ({
									...provided,
									width: "100%",
									minWidth: "250px"
								}),
								
							})}
							className="noLeftRadius"
							onChange={(selectedOption) => {
								setSelectedStore(selectedOption.value)
							}}
							options={refConst.stores.filter(o => {
									if(o.id_marketplace == 14)	return o
									return false
							}).map(o => {
								return {
									value: o.id,
									label: o.name
								}
							})}
							value={[storeInfo? {value: storeInfo.id, label: storeInfo.name} : null]}
							defaultValue={[storeInfo? {value: storeInfo.id, label: storeInfo.name} : null]}
							isSearchable={true}
						/>
					</div>
					</div>
					<div className="container bg-light p-3 border mb-4 position-relative">
						<span className="position-absolute border rounded pl-1 pr-1 bg-light noselect" style={{top: "-12px"}}>Special tags</span>
						{
							specialTags.map(o => {
								return <div key={o.name+"_spacialTag"} className="row mt-1">
									<div className="col-sm"><button className="btn btn-light border btn-sm" onClick={(e) => {
										e.preventDefault();
										// addTagsForAll(o.name)
										inputAdd.current.value = o.name
									}}>{ o.name }</button></div>
									<div className="col">{ o.description }</div>
								</div>
							})
						}
						<div className="input-group w-100 mt-2">
							<div className="input-group d-flex flex-row flex-nowrap">
								{/* <div className="input-group-prepend">
									<span className="input-group-text">Store</span>
								</div>
								<div className="input-group-prepend">
									<select className="custom-select" value={selectedStore ?? ""} style={{borderRadius: "0px"}} onChange={(e) => {
										setSelectedStore(e.target.value)
									}}>
										{
											refConst.stores.filter(o => {
												if(o.id_marketplace === 14)
													return o
												return false
											}).map(o => {
												return <option key={`${o.id}_listStores`} value={o.id}>{o.name}</option>
											})
										}
									</select>
								</div> */}
								<div className="input-group-prepend">
									<span className="input-group-text">Tag Group</span>
								</div>
								<Select
									styles={Object.assign(refConst.styleList, {
										container: (provided) => ({
											...provided,
											width: "80%",
											minWidth: "250px"
										}),
									})}
									className="noLeftRadius"
									onChange={(selectedOption) => {
										setSelectedTagGroup(selectedOption)
										// addTagsForAll(selectedOption.value)
										inputAdd.current.value = selectedOption.value
									}}
									options={(() => {
										let out = [];
										tagGroups.forEach(m => {
											m.data.forEach(o => {
												out.push({
													value: m.prefix + o.name,
													label: m.name + ' - ' + o.name
												})
											})
										})
										console.info("outoutoutout", out);
										return out
									})()}
									value={[selectedTagGroup? selectedTagGroup : null]}
									defaultValue={[selectedTagGroup? selectedTagGroup : null]}
									isSearchable={true}
								/>
							</div>
						</div>
					</div>
					<div className="container bg-light p-3 border mb-4 border-dark rounded position-relative">
						<span className="position-absolute border rounded pl-1 pr-1 bg-light border-dark noselect" style={{top: "-12px"}}>Bulk add</span>
						{
							items.length == 0? <div className="alert alert-primary mt-3" role="alert">
								If you wish to apply a tag to a selection of products, you first need to select these products and click on action -&gt; change tags
							</div> : ""
						}
						{
							items.length > 0? <div className="text-center mb-4">
								<span className="font-weight-bold">To what would you like to apply the tag to?</span>
								<div className="form-check d-flex align-items-center justify-content-center mt-2">
									<input className="mr-3" name="btnRadio" checked={appyTagToItems} type="radio" id="selectedItemRadio" value="option1" defaultChecked={true} onChange={() => setAppyTagToItems(true)} style={{width: "25px", height: "25px"}}/>
									<label className="form-check-label" htmlFor="selectedItemRadio">
										Selected items {items.length > 0? `(count: ${items.length})` : ""}
									</label>
								</div>
								<div className="form-check d-flex align-items-center justify-content-center mt-2">
									<input className="mr-3" name="btnRadio" checked={!appyTagToItems} type="radio" id="categoryRadio" value="option2" onChange={() => setAppyTagToItems(false)} style={{width: "25px", height: "25px"}}/>
									<label className="form-check-label" htmlFor="categoryRadio">
										Category
									</label>
								</div>
							</div> : ""
						}
						{
							
							!appyTagToItems? <div className="mb-3">
								<p className="font-weight-bold">Please select a Collection*</p>
								<div className="input-group d-flex flex-row flex-nowrap">
									{/* <div className="input-group-prepend">
										<span className="input-group-text">Store</span>
									</div>
									<div className="input-group-prepend">
										<select className="custom-select" value={selectedStore ?? ""} style={{borderRadius: "0px"}} onChange={(e) => {
											setSelectedStore(e.target.value)
										}}>
											{
												refConst.stores.filter(o => {
													if(o.id_marketplace === 14)
														return o
													return false
												}).map(o => {
													return <option key={`${o.id}_listStores`} value={o.id}>{o.name}</option>
												})
											}
										</select>
									</div> */}
									<div className="input-group-prepend">
										<span className="input-group-text">Categories</span>
									</div>
									<Select
										styles={Object.assign(refConst.styleList, {
											container: (provided) => ({
												...provided,
												width: "80%",
												minWidth: "250px"
											}),
										})}
										className="noLeftRadius"
										onChange={(selectedOption) => setSelectedCategory(selectedOption)}
										options={(() => {
											return categories.map(m => {
												return {
													value: m.last.id_category,
													label: (m.last.site? `[${m.last.site}] ` : "") + m.inline
												}
											})
										})()}
										value={[selectedCategory? selectedCategory : null]}
										defaultValue={[selectedCategory? selectedCategory : null]}
										isSearchable={true}
									/>
								</div>
							</div> : ""
						}
						<p className="font-weight-bold">Now set up the details about the tag:</p>
						<form onSubmit={triggerAddtag} className="d-flex flex-row align-items-start justify-content-between">
							<div className="input-group w-50">
								<div className="input-group-prepend">
									<span className="input-group-text" id="basicTagAdd">Tag to adds</span>
								</div>
								{/* <div className="input-group-prepend">
									<span className="" id="basicTagAdd">
										<select className="custom-select" style={{borderRadius: "0px"}} placeholder="Special" onChange={(e) => {
											// console.info('inputAdd.current', inputAdd.current);
											inputAdd.current.value = e.target.value
										}}>
											<option value="">-- Special tag -- </option>
											{
												specialTags.map(o => {
													return <option key={`${o.name}_specialTags`} value={o.name}>{o.name}</option>
												})
											}
										</select>
									</span>
								</div> */}
								<input ref={inputAdd}/*  value="specialA+++" */ type="text" className="form-control" placeholder={"Tag name"}/>
								<button type="button" className="btn bg-light border ml-3" onClick={() => {
									setTimerSectionOpen(!timerSectionOpen)
								}}>Timer <FontAwesomeIcon className="ml-2" icon={faCalendarAlt}/></button>
								{
									dateFrom && dateTo? <button type="button" className="btn btn-warning ml-3" onClick={() => {
										setDateFrom(null)
										setDateTo(null)
									}}>Reset Timer</button> : ""
								}
							</div>
							{dateFrom && dateTo? <span className="badge badge-pill badge-warning mr-3">Timed</span> : ""}
							<button type="submit" className="btn btn-primary">{appyTagToItems? "Add to each item" : "Add to each item in collection"}</button>
						</form>
						<div className={(timerSectionOpen? "" : "d-none") + " pt-3"}>
							<DateRange
								dateFrom={dateFrom}
								dateTo={dateTo}
								setDateFrom={(d) => {
									console.info('d From', d);
									setDateFrom(moment(d).format("YYYY-MM-DD"))
								}}
								setDateTo={(d) => {
									console.info('d To', d);
									setDateTo(moment(d).format("YYYY-MM-DD"))
								}}
							/>
						</div>
					</div>
					<div className="container bg-light p-3 border mb-4 timedSection position-relative">
						<span className="position-absolute border rounded pl-1 pr-1 bg-light border-dark noselect pointer" style={{top: "-12px"}} onClick={() => {
							if(!showTimedTags){
								// Load all our timed tags
								refreshDbData()
							}else{
								if(window.intervalTagGestion)
									clearInterval(window.intervalTagGestion)
							}
							setShowTimedTags(!showTimedTags)
						}}>See Timed Tags <FontAwesomeIcon  icon={!showTimedTags? faChevronCircleDown : faChevronCircleUp}/></span>
						{!showTimedTags? <div className="text-center"><button className="btn btn-sm border" onClick={() => {
							if(!showTimedTags){
								// Load all our timed tags
								refreshDbData()
							}else{
								if(window.intervalTagGestion)
									clearInterval(window.intervalTagGestion)
							}
							setShowTimedTags(!showTimedTags)
						}}>Click to see the timed tags</button></div> : ""}
						<table className={(showTimedTags? "" : "d-none ") + "table table-bordered table-sm mt-3 mb-0"}>
							<thead>
								<tr style={{backgroundColor: "#dfdfdf"}}>
									<th className="text-center smallText align-middle" style={{borderTopLeftRadius: "5px"}}>Name</th>
									<th className="text-center smallText align-middle">Creator</th>
									<th className="text-center smallText align-middle">Category</th>
									<th className="text-center smallText align-middle">Type</th>
									<th className="text-center smallText align-middle">Dates Start</th>
									<th className="text-center smallText align-middle">Dates End</th>
									<th className="text-center smallText align-middle">Flag</th>
									<th className="text-center smallText align-middle" style={{borderTopRightRadius: "5px"}}>Action</th>
								</tr>
							</thead>
							<tbody>
								{
									showTimedTags?
										(timedTags?
											timedTags.length > 0? timedTags.map(o => {
												return <tr key={`${o.id}_timedTags`}>
													<td className={"text-center align-middle text-white font-weight-bold " + (o.ended? (o.started? "bg-danger" : "") : (o.started? "bg-success" : "bg-info"))}>{ o.tag }</td>
													<td className="text-center smallText align-middle">{ o.account.email? o.account.email.split("@")[0] : "-" }</td>
													<td className="text-center smallText align-middle">{ o.category_name }</td>
													<td className="text-center smallText align-middle" title={o.id_product_shopify_list}>{ o.id_product_shopify_list? o.id_product_shopify_list.split(",").length : "" } products selected</td>
													<td className="text-center smallText align-middle">
														{ moment(o.starting_date || o.date).format("YYYY-MM-DD HH:mm") }
													</td>
													<td className="text-center smallText align-middle">
														{ o.ending_date? moment(o.ending_date).format("YYYY-MM-DD HH:mm") : (o.starting_date? "Ongoing" : "N/A") }
													</td>
													<td className="text-center">
														{
															!o.instant_execute? <div>
																{o.started && !o.ended? <span className="badge badge-success ml-2">Active</span> : ""}
																{o.ended? <span className="badge badge-info ml-2">Finished</span> : ""}
															</div> : <div>
																<span className="badge badge-info">Instant Active</span>
																{o.started && !o.ended? <span className="badge badge-success ml-2">Active</span> : ""}
																{o.ended? <span className="badge badge-info ml-2">Finished</span> : ""}
															</div>
														}
													</td>
													<td className="text-center smallText align-middle">
														{
															!o.instant_execute? <div>
																{!o.started && !o.market_for_ending? <button className="btn btn-danger btn-sm ml-1 mr-1" onClick={() => { removeTimedTag(o) }}><FontAwesomeIcon  icon={faTrash}  title="Will delete the timer"/></button> : ""}
																{o.started && !o.ended && !o.market_for_ending? <button className="btn btn-warning btn-sm ml-1 mr-1" onClick={() => { removeTimedTag(o) }}><FontAwesomeIcon  icon={faHistory} title="Will remove the tag & end the timer"/></button> : ""}
															</div> : <div>
	
																{o.started && !o.ended && !o.market_for_ending? <button className="btn btn-warning btn-sm ml-1 mr-1" onClick={() => { removeTimedTag(o) }}><FontAwesomeIcon  icon={faHistory} title="Will remove the tag & end the timer"/></button> : ""}
																{o.ended? <button className="btn btn-warning btn-sm ml-1 mr-1" onClick={() => { restoreTag(o) }}><FontAwesomeIcon  icon={faTrashRestoreAlt} title="Reactivate"/></button> : ""}
															</div>
														}
													</td>
												</tr>
											}) : <td className="text-center" colSpan={8}>No Result</td>
										: <td style={{height: "100px"}} className="position-relative" colSpan={8}><Loading loading={true} centered={true} /></td>)
									: <tr>
										<td colSpan="8" className="text-center">No Result</td>
									</tr>
								}
							</tbody>
						</table>
					</div>
					<div className={(appyTagToItems? "" : "d-none") + " container bg-light p-3 border mb-4 position-relative"}>
						<span className="position-absolute border rounded pl-1 pr-1 bg-light noselect" style={{top: "-12px"}}>Bulk Remove</span>
						<form onSubmit={triggerDeltag}>
							<div className="row mt-3">
								<div className="col-sm">
									{
										<Select
											styles={Object.assign(refConst.styleList, {
												control: provided => ({
													...provided,
													borderTopLeftRadius: "4px",
													borderBottomLeftRadius: "4px"
												})
											})}
											onChange={(selectedOption) => {
												console.info('selectedOption.value ', selectedOption.value );
												
												setSelectedToDel(selectedOption && selectedOption.value != '-'? selectedOption.value : initialState.selectedToDel)
												/* let pos = allTags.indexOf(selectedToDel);
												if(pos !== -1){
													allTags.splice(pos, 1)
												}
												setAllTags(allTags.slice(0)) 
												*/
											}}
											options={(() => {
												return allTags.map(t => {
													return {
														value: t,
														label: t
													}
												})
											})()}
											value={selectedToDel !== initialState.selectedToDel? {value: selectedToDel, label: selectedToDel} : null}
											isSearchable={true}
											isMulti={false}
											placeholder={"Select what to remove"}
										/>
									}
								</div>
								<div className="col-sm">
									<button type="submit" className="btn btn-primary">Delete from each item</button>
								</div>
							</div>
						</form>
					</div>
					<div className={appyTagToItems? "" : "d-none"}>
						{ appyTagToItems? <p className="h5 mb-2 ml-1">Item compatible for the selected marketplace: {items.length}</p> : "" }
						<table className="table">
							<thead>
								<tr className="table-sm" style={{backgroundColor: "#dfdfdf"}}>
									<th scope="col" className="text-center smallText align-middle" style={{borderTopLeftRadius: "5px"}}>Id</th>
									<th scope="col" className="text-center smallText align-middle">Brand</th>
									<th scope="col" className="text-center smallText align-middle">SKU</th>
									<th scope="col" className="text-center smallText align-middle" style={!submited? {borderTopRightRadius: "5px"} : null}>Tags</th>
									{submited? <th scope="col" className="bg-primary text-white" style={{borderTopRightRadius: "5px"}}>Status</th> : null}
								</tr>
							</thead>
							<tbody>
								{items.length > 0?
									filtered().map(o => {
										if(!("newToRemove" in o))	o.newToRemove = []
										return	<tr key={o.id + "lineTagsSystem"}>
											<th className="text-center smallText align-middle" scope="row">{o.id}</th>
											<td className="text-center smallText align-middle">{o.vendor}</td>
											<td className="text-center smallText align-middle">{o.sku}</td>
											<td className="text-center smallText align-right">
												{
													<CreatableSelect
														styles={refConst.styleList}
														className="m-auto"
														onChange={(selectedOption) => {
															// console.info('selectedOptionselectedOption', selectedOption);
															let listToRemove = []

															selectedOption.forEach(objReceived => {
																if("__isNew__" in objReceived && objReceived.__isNew__){
																	o.tags.push(objReceived.value)
																}else{
																	listToRemove.push(objReceived.value)
																}
															})
															
															o.newToRemove = listToRemove
														
															setItems(items.slice(0))
														}}
														options={(() => {
															return o.tags.map(t => {
																return {
																	value: t,
																	label: t
																}
															})
														})()}
														value={o.newToRemove.length > 0? o.newToRemove.map(t => {
															return {value: t, label: t};
														}) : null}
														isSearchable={true}
														isMulti={true}
														placeholder={"Select what to remove"}
													/>
												}
											</td>
											{
												submited?
													<td>
														{
															Object.keys(itemsCreated).indexOf(o.id_product_shopify.toString()) !== -1? <div className="d-flex">
																<button type="button" className="btn btn-primary btn-sm bg-secondary text-white mb-1"><a href={"https://" + objPreSelected.shopName + ".myshopify.com/admin/products/" + itemsCreated[o.id_product_shopify.toString()].id} className="text-white" target="_blank" rel="noopener noreferrer">Admin</a></button>
																<button type="button" className="btn btn-primary btn-sm bg-secondary text-white mb-1 ml-2"><a href={"https://" + objPreSelected.shopName + ".myshopify.com/products/" + itemsCreated[o.id_product_shopify.toString()].handle} className="text-white" target="_blank" rel="noopener noreferrer">Store</a></button>
															</div> : (Object.keys(itemsCreated).length === 0? '-' : 'Something Wrong')
														}
													</td> : null
											}
										</tr>
									})
									: <tr>
									<td colSpan="4" className="text-center">No item found</td>
								</tr>}
							</tbody>
						</table>
					</div>
				</div>
			}
			, exit: (popup, close) => {
				console.info("window.intervalTagGestion", window.intervalTagGestion);
				if(window.intervalTagGestion)
					clearInterval(window.intervalTagGestion)
				close()
			}
			, ok: !submited && appyTagToItems && !isTimed? {
				title: "Validate",
				fnc: (popup, close) => {
					// props.loading(true)
					setSubmited(true)

					if(appyTagToItems && !isTimed){

						let objToSend = []
						items.forEach( o => {
							if(o.id_product_shopify && o.tags.length > 0){
	
								if(o.newToRemove.length > 0){
									o.tags.forEach((t, i) => {
										if(o.newToRemove.indexOf(t) !== -1)	o.tags.splice(i, 1)
									})
								}
	
								o.tags.forEach(t => {
									required.forEach((w, i) => {
										let pos = t.indexOf(w)
										if(pos !== -1){
											required.splice(i, 1)
											return false;
										}
									})
								})
	
								let valid = required.length === 0? true : false
	
								if(valid)
									objToSend.push( {
										id: o.id_product_shopify,
										tags: o.tags
									})
							}
						})
						console.info('Items_send', objToSend);
						
						reqCreatItems(selectedStore, objToSend, (r) => {
							// setSubmited(false)
							props.loading(false)
	
							let success = [],
								error = [],
								itemsCreatedList = {};
	
							console.info('Fetch Req Create Item', r);
							if(!r || !r.data){
								props.info({error: "Something bad happend in 'reqCreatItems'."})
							}else{
								if(r.data.updated.length > 0){
									r.data.updated.forEach(iu => {
										itemsCreatedList[iu.idReceived] = {handle: iu.handle, id: iu.id_response}
									})
		
									setItemsCreated(itemsCreatedList)
									// resetPage()
									success.push("Items inserted ("+Object.keys(itemsCreatedList).length+"): ")
									success.push(Object.keys(itemsCreatedList).join(', '))
								}
							}
	
							if(r.error)		error.push(r.error)
	
							props.info({success: success, error: error})
						})
					}
					// popup.modal('hide')
					if(window.intervalTagGestion)
						clearInterval(window.intervalTagGestion)
				}
			} : false, return: {
				title: "Close",
				fnc: (popup, close) => {
					if(window.intervalTagGestion)
						clearInterval(window.intervalTagGestion)
					resetPage()
					close()
				}
			}
		});
	}

	let reqCreatItems = (storeId, itemList, callbackPresence) => {
		if(!storeId || !itemList)
			throw("Please indicate a storeId / itemList.");
		else
			ajaxPerso( {
				"api": "shopify",
				"trigger": "bulkTagsChange",
				"shop": storeId,
				"itemList": JSON.stringify(itemList)
			}, callbackPresence);
	}

	let fetchShopifyItems = (storeId, idProductList, callbackPresence) => {
		console.info("idProductListidProductList", idProductList);
		if(!storeId)
			// throw("Please indicate a list of product ID.");
			props.info({
				error: "These item do not have a store ID, please make sure they exist on shopify"
			})
		else if(idProductList.length > 0)
			ajaxPerso( {
				"api": "shopify",
				"trigger": "getItems",
				"shop": storeId,
				"idProductListJson": JSON.stringify(idProductList),
			}, callbackPresence);
		else{
			setItems(initialState.items.slice(0))
		}
	}

	let getShopifyitems = (storeId, idProductList) => {
		fetchShopifyItems(storeId, idProductList, (r) => {
			if(r.success){
				console.info('Fetched_items', r);
				setItems(r.data)
			}else{
				props.info({"error": r.error})
			}
		})
	}

	let storeFromToNames = () => {
		if(props.stores.length === 0)	return ""
		
		let store = null;
		store = props.stores.find(o => {
			if(o.id === props.store)	return o
		})

		if(store)
			return store.name
		else
			return "Tag Manager"
	}

	console.info("propspropsprops", props);
	console.info("categories", categories);
	console.info("timedTags22", timedTags);
	console.info("timerSectionOpen", timerSectionOpen);
	console.info("tagGroupstagGroups", tagGroups);
	console.info("itemsitemsitems", items);

	return (
		"isButton" in props && props.isButton? <button className={"classname" in props && props.classname? props.classname : "btn btn-outline-primary"} onClick={() => triggerClick(props.storeTo, props.storeFrom)}>
			{
				storeFromToNames()
			}
		</button> : 
		<div className="list-group-item list-group-item-action p-2 text-center" onClick={() => triggerClick(props.storeTo, props.storeFrom)}>
			{
				storeFromToNames()
			}
		</div>
	);
}