// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import $ from 'jquery'; 
import { fetchApiData, socketPerso } from '../../../fnc'
import moment from 'moment';

const refConst = require("../../../constants.js").v

var initialState = {
	_isMounted: false
}

export default function ManufacturerMain(props){

	useEffect(() => {
		$('.tooltipQuickViewStatus').tooltip({
			placement: "bottom",
			template:  '<div class="tooltip" role="tooltip"><div class="arrow"></div><div class="tooltip-inner tooltip-info-ebay"></div></div>',
		}).tooltip('update')

		$('.tooltipQuickViewStatus').on('click', function() {
			$('.tooltip').tooltip('hide');
		});
	})

	useEffect(() => {
		console.info('refConst', refConst);
		
		initialState._isMounted = true;
		
		return () => initialState._isMounted = false
	}, [])

	let submitTaskitemCountPerbrandAndStore = () => {

		props.info({success: `Task started, you will receive an email with the download link.`})
		//  api=eio trigger=getInventoryCount verbose=true all=true createReport=true uid=4XTCWbwcFscWYkXaOXQeSbVqE6o2

		socketPerso("task", {
			url: refConst.urlServer,
			//  api=eio trigger=3M_finale_shipping_type_calculator verbose=true updateFinale=false createFile=true uid=4XTCWbwcFscWYkXaOXQeSbVqE6o2 resfreshDb=false
			internalReq: {
				"method": "POST",
				"api": "eio",
				"trigger": "getInventoryCount",
				"updateFinale": false,
				"createReport": true,
				"all": true,
				"supplierBase": false,
				"uid": props.getUser().uid
			},
			task: {
				op: "insert",
				type: "report",
				args: {
					uid: props.getUser().uid,
					name: `Create report Inventory Count Per Brand & Store`
				}
			}
		}, (passThrough) => {
			console.info("Res socket", passThrough);

			if(passThrough.errors){
				props.info({"error": passThrough.errors})
			}else{

				console.info("Success pastrhough: ", passThrough);
			}
		})
	}

	if(!props.securityClearance(props))	return "";


	return (<div className="m-3 border rounded d-inline-block align-top" style={{
		backgroundColor: "rgb(238, 238, 238)"
	}}>
		<div className="text-center ml-3 mr-3">Report Generation</div>
		<div style={{
			maxHeight: "300px",
			height: "300px",
			overflowY: "auto",
			overflowX: "hidden",
			boxShadow: "inset 0px 0px 5px 0px rgb(173 173 173)"
		}} className="bg-light ml-2 mr-2 mb-2 rounded">
			<div className="text-center">
				<div className="p-3 rounded d-flex justify-content-between align-items-center">
					Items Count per Store and per Brand
					<button type="button" className="btn btn-outline-primary ml-3" onClick={submitTaskitemCountPerbrandAndStore}>Generate</button>
				</div>
			</div>
		</div>
	</div>);
}