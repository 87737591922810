// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import moment from 'moment';
import Loading from './Loading';
// import {  } from '../../fnc'
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';

const refConst = require("../../constants.js").v

export default function DateRange(props){
	
	const refInputFrom = useRef(null);
	const refInputTo = useRef(null);

	return (
		<div className="d-flex flex-row justify-content-center align-items-start">
			{
				[
					{
						ref: refInputFrom,
						date: props.dateFrom || moment().format("YYYY-MM-DD"),
						setDate: props.setDateFrom,
						position: "left",
					},{
						ref: refInputTo,
						date: props.dateTo || moment().add(1, 'months').format("YYYY-MM-DD"),
						setDate: props.setDateTo,
						position: "right",
					}
				].map(o => {
					return <div key={`${o.position}`} className="d-flex flex-column">
						<input
							style={{ fontSize: "inherit" }}
							ref={o.ref}
							className="w-75 m-auto text-dark form-control"
							type="text"
							value={o.date? moment(o.date).format("MM-DD-YYYY") : ""}
							placeholder={"MM-dd-yyyy"}
							onChange={(e) => handleInputChange(e, o)}
						/>
						<DayPicker
							captionLayout="dropdown" fromYear={2010} toYear={2024}
							className="Selectable"
							// selectedDays={[o.date]}
							month={moment(o.date).toDate()}
							selectedDays={moment(o.date).toDate()}
							// modifiers={{ start: props.setDateFrom, end: to }}
							onDayClick={(result) => {
								console.log("result", moment(result).format("YYYY-MM-DD"));
								o.setDate(result)
								o.ref.current.value = moment(result).format("MM-DD-YYYY")
								// setRefreshView(refreshView + 1)
							}}
						/>
					</div>
				})
			}
		</div>
	);
}