// eslint-disable-next-line no-unused-vars
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import ReactDOM, { findDOMNode } from 'react-dom'
import ReactDOMServer from 'react-dom/server';
import $ from 'jquery'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons'
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons'
import { ajaxPerso, fetchMenu, menuOrganized, fetchDataSet, clipboard, diff, loadMinSellingPrices, fetchShippingRate, imgLoaderWithControl } from '../../../fnc'
import Menu from './Menu'
import { faClipboard } from '@fortawesome/free-regular-svg-icons'
import Select from 'react-select';
import {
	NavLink
} from 'react-router-dom';
// import logoStoreAmazon from '../../img/amazon_circle_perso.png';
import moment from 'moment';

var initialState = {
	_isMounted: false,
	categoriesStoreTo: [],
	dataSet: [],
}

const refConst = require("../../../constants.js").v


refConst.styleList.multiValueLabel = (styles, { data }) => ({
    ...styles,
    color: data.color,
})
refConst.styleList.multiValue = (styles, { data }) => {
	// console.info("datadata", data);
	return {
		...styles,
		backgroundColor: data.background,
		color: data.color,
	}
}
function ListView(props){

	const [categoriesStoreTo, setCategoriesStoreTo] = useState(initialState.categoriesStoreTo);
	const [dataSet, setDataSet] = useState(initialState.dataSet);
	const [updateView, setUpdateView] = useState(0);
	const [storeListForCreation, setStoreListForCreation] = useState([]);
	const [refreshView, setRefreshView] = useState(0);
	const [versionImgAdder] = useState(0.1);
	const refUrlImgAdder = useRef();


	useEffect(() => {
		console.info('refConst', refConst);
		
		initialState._isMounted = true;

		init()
		
		return () => initialState._isMounted = false
	}, [])

	useEffect(() => {
		
		if(refConst.stores.length > 0)
			setStoreListForCreation(getFullStoreList())
		
		return () => initialState._isMounted = false
	}, [refConst.stores])

	const init = (e, o) => {
		fetchMenu( {
			"shop": refConst.id_store_eio
		}, () => {

		}, (r) => {
			if(!initialState._isMounted)	return false

			if(r.success){
				console.log("Fetched Categories for " + refConst.id_store_eio, r);
				let results = r.data
				let menu = menuOrganized(results)
	
				setCategoriesStoreTo(menu)
			}
		});

		fetchDataSet({
			"shop": refConst.id_store_eio,
			"level": 2,
			"justData": true
		}, (r) => {
			if(!initialState._isMounted)	return false

			if(r.success){
				console.log("Fetched dataset for " + refConst.id_store_eio, r);
				setDataSet(r.data)
			}
		})
	}

	const submitQtyPack = (e, o, pricesPerType, typeName) => {
		e.preventDefault()

		let qtySelected = typeName

		if(qtySelected) {
			// We make sure that this qty do not already exist.
			let found = o.variants.find(pqc => {
				if(pqc.selected_saleable_unit == qtySelected) return pqc
			})
			// We need to find the next "Unit sold as" and use it as QTY

			/* console.info(`submitQtyPack:`, found);
			console.info(`qtySelected:`, qtySelected);
			console.info(`pricesPerType:`, pricesPerType);
			console.info(`oo:`, o); */

			if(!found)
				o.variants.push({
                    // "id_variant": null,
                    "qty": qtySelected,
                    "selling_price": {},
					"default_price": {}, // Just for the view
					"active": true,
                    "createStatus": {
                        "created": null,
                        "msg": null,
                        "date_Created": null
                    },
                    "sku": `${o.sku}_${qtySelected}`,
                    "storeWanted": props.storeWantedForOurBrand,
                    "presenceCheckedResult": [],
					"presenceInfo": null
                })

			// Prefile the price in case there is a price option,
			// We need to get the minSellingPrice first
			if(qtySelected){

				o.variants[o.variants.length-1].selected_saleable_unit = qtySelected

				let weight = null,
					weightUnit = null

				// Get the weight and weight unit
				o.bcom_sizes.some(bS => {
					if(bS.name.toLowerCase() == qtySelected.toLowerCase()){
						if(bS.dims && "weight" in bS.dims){
							convertDim(bS.dims)
							weight = bS.dims.weight.value.toFixed(3)
							weightUnit = bS.dims.weight.type
							return true
						}
					}
				})

				if(weight && weightUnit){

					// let pD = o.api_price_result.pricingData.find(pd => {
					// 	if(pd.unit.name.toLowerCase() == qtySelected.toLowerCase()){
					// 		return pd
					// 	}
					// })

					if(qtySelected.toLowerCase() in pricesPerType)
						loadMinSellingPrices([{
							"sku": o.sku,
							"weight": weight,
							"weight_unit": weightUnit,
							"cost": props.getCorrectCostDataFromPricingData(pricesPerType[qtySelected.toLowerCase()]).netPrice,
						}], (dr) => {

							dr.data.some(d => {
								if(d.sku == o.sku){

									// console.info("min Selling price found and added", d);
									o.variants[o.variants.length-1].minSellingPrice = d.minSellingPrice
									o.variants[o.variants.length-1].rate = d.rate

									updateVariantsDefaultPrices(
										pricesPerType
										, parseFloat(pricesPerType[qtySelected].currentPrice.listPrice)
										, parseFloat(pricesPerType[qtySelected].currentPrice.listPrice)
										, o.variants[o.variants.length-1],
										o.variants[o.variants.length-1].minSellingPrice,
										false
									)
									return d;
								}
							})
						})
				}else{
					updateVariantsDefaultPrices(
						pricesPerType
						, parseFloat(pricesPerType[qtySelected].currentPrice.listPrice)
						, parseFloat(pricesPerType[qtySelected].currentPrice.listPrice)
						, o.variants[o.variants.length-1],
						[],
						false
					)
				}
			}

			setRefreshView(refreshView+1)
		}
	}

	const getFullStoreList = (e, o) => {
		/**
			List all store accepted for creation
		 */
		let idWanted = [
			refConst.id_store_finale,
			refConst.id_store_hausfTools,
			refConst.id_store_eio,
			refConst.id_store_eBay_EIO,
		]
		return refConst.stores.filter(s => {
			if(idWanted.indexOf(s.id) !== -1)	return s
			return false
		})
	}

	const addImgUrlToProduct = (idAutoCreateProduct, urls, callback) => {
		// props.loading(true);

		if(!idAutoCreateProduct || !urls)
			return;

		/* console.info("Submit", JSON.stringify(data));
		return */

		ajaxPerso( {
			"api": "autoCreator",
			"trigger": "alter_auto_creator_product",
			"data": JSON.stringify([{
				"id": idAutoCreateProduct,
				"image_urls_added": JSON.stringify(urls)
			}])
		}, (r) => {
			console.info("Trigger addImgUrlToProduct", r);
			if(r.success && r.res.updated){
				props.info({success: "Product Img Updated"})
				refUrlImgAdder.current.value = ""
				if(callback)	callback(r)
			}else
				props.info({error: "Error while updating the product."})
		});
	}

	const updateVariantsDefaultPrices = (pricesPerType, currentDefaultPrice, newPrice, variant, minSellPricePerStore, isUpdate) => {
		

		if(newPrice && variant.storeWanted.length > 0){
			let changedSomething = false

			console.info("changedSomething--", `${currentDefaultPrice} - ${JSON.stringify(variant.selling_price)} - ${newPrice}`);
			console.info("variant.storeWanted--", variant.storeWanted);
			
			variant.storeWanted.forEach(sWid => {
				
				let newPriceValue = minSellPricePerStore && minSellPricePerStore[sWid]? minSellPricePerStore[sWid] : newPrice;

				if(
					sWid != refConst.id_store_finale && (
						// If no price was defined for this current store, we just add the default value
						//! (sWid in variant.selling_price && !variant.selling_price[sWid]) ||
						// If a value exist for this store, then we compare it to know if it needs to be changed
						(!variant.default_price[sWid] || variant.default_price[sWid] != newPriceValue)
					)
				){
					changedSomething = true
					variant.default_price[sWid] = newPriceValue
					console.info("updateVariantsDefaultPrices new value", newPriceValue);
				}
			})

			/* console.info("changedSomething", changedSomething);
			console.info("changedSomething2", variant);
			console.info("changedSomething3", newPrice);
			console.info("changedSomething4", pricesPerType); */
			// debugger
			if(changedSomething || isUpdate){
				props.setAutoCreateProduct(props.autoCreateProduct.slice(0))
				// setRefreshView(refreshView+1)
			}
		}
	}

	const filterValidData = () => {

		console.info("filterValidData", props.autoCreateProduct);

		let storeInfoPerStoreId = {}
		props.storeInfo.forEach((sI, sIPos) => {
			if(sI.active)
				storeInfoPerStoreId[sI.storeId] = sI
		})

		let cp = JSON.parse(JSON.stringify(props.autoCreateProduct))
		let dataToSend = cp.filter(o => {

			delete o.product_feed_data;
			delete o.api_price_result;
			delete o.api_search_result;
			delete o.bcom_result;
			
			o.reviewed = 1; // ByPass the review mode

			o.variants = o.variants.filter(pqc => {
				// We need to make sure that every wanted store has a price (exept Finale)
				
				let priceMissing = false
				pqc.storeWanted.some(sW_id => {
					let sI = storeInfoPerStoreId[sW_id]
					if(sW_id != refConst.id_store_finale && 
						( // We have to make sure not to consider a price for Finale
							!(sW_id in pqc.selling_price) || 
							!parseFloat(pqc.selling_price[sW_id]) || 
							pqc.selling_price[sW_id] < pqc.default_price[sW_id] // We make sure the price indicated is biggger or equa to the minimum
						) &&
						(!("noPrice" in sI) || !sI.noPrice)
					) {
						// console.info("Passssedddd", sI);
						return priceMissing = true
					}
				})
				if(!priceMissing) { // pqc.active && 
					return pqc
				}
			})
			if(o.variants && o.variants.length > 0 && o.active || o.removed)
				return o
			return false
		})

		return dataToSend
	}

	const paginationGrouped = () => {

		let groupHtmlOut = [],
			maxCtn = Math.ceil(props.itemsCountSelectedCat/props.pageSize)

		for (let i = 0; i < maxCtn; i++) {
			
			groupHtmlOut.push(<button key={`${i+1}_paginationAutoCreate`} className={(props.pageOpen == i? "active" : "") + " btn btn-outline-info mr-2"} onClick={() => {
				props.setPageOpen(i)
			}} title={`${i*props.pageSize} to ${i < maxCtn-1? (i+1)*props.pageSize : props.itemsCountSelectedCat}`}>
				{ i+1 }
			</button>)
		}
		return groupHtmlOut
	}

	const filterPerPagination = (productList) => {

		// Remove some data base on our filters.
		productList.forEach((o, pos) => {
			o.variants.forEach((v, posV) => {

				props.storeInfo.forEach((sI, sIPos) => {
		
					if("removed" in sI)
						v.storeWanted.some((lA, lAPos) => {
							if(sI.active && sI.storeId == lA){
								v.storeWanted.splice(lAPos, 1)
								return true
							}
						})
				})
			})
		})

		return productList.slice(0)
		// return productList.slice(props.pageOpen*props.pageSize, (props.pageOpen+1)*props.pageSize)
	}

	const convertDim = (dim) => {
		// Convert any metric to imperial
		for (const type in dim) {
			if (Object.hasOwnProperty.call(dim, type)) {
				const obj = dim[type];
				if(obj.type == "cm"){
					dim[type].value = obj.value * 0.393701
					dim[type].type = "in"
				}else if(obj.type == "mm"){
					dim[type].value = obj.value * 0.0393701
					dim[type].type = "in"
				}else if(obj.type == "m"){
					dim[type].value = obj.value * 39.3701
					dim[type].type = "in"
				}else if(obj.type == "kg"){
					dim[type].value = obj.value * 2.20462
					dim[type].type = "lb"
				}else if(obj.type == "g"){
					dim[type].value = obj.value * 0.00220462
					dim[type].type = "lb"
				}else if(obj.type == "l"){
					dim[type].value = obj.value * 0.264172
					dim[type].type = "gal"
				}else if(obj.type == "ml"){
					dim[type].value = obj.value * 0.000264172
					dim[type].type = "gal"
				}else if(obj.type == "oz"){
					dim[type].value = obj.value * 0.0625
					dim[type].type = "lb"
				}
			}
		}
	}

	const viewListMeta = () => {
 
		let storeListIdToName = {}
		props.storeInfo.forEach(s => {
			storeListIdToName[s.storeId] = s.name;
		})

		let output = <div className="pl-4 pr-4 pb-4">
			{
				props.itemsCountSelectedCat > 0?
					<div className="p-3 bg-dark rounded" style={{
						overflowY: "auto",
					}}>
						{
							paginationGrouped()
						}
					</div> : ""
			}
			<div className="list-group overflow-auto">
				<table className="table" style={{marginBottom: "100px"}}>
					<thead>
						<tr>
							<th className="position-sticky" style={{
								zIndex: 9980,
							}}>
								{
									props.itemsCountSelectedCat === 0? <div className="alert alert-danger" role="alert">
										This category has not product awaiting to create.
									</div> : 
									<span>
										info {filterPerPagination(props.autoCreateProduct).length} product
										<div className="btn-group btn-group-toggle" data-toggle="buttons">
											<button type="button" className="btn btn-success ml-3 btn-sm" onClick={() => {
												props.autoCreateProduct.forEach(o => {
													o.active = true
													if("removed" in o)
														delete o.removed
												})
												setRefreshView(refreshView+1)
											}}>Select all to publish</button>
											<button className="btn btn-dark btn-sm" data-toggle="tooltip" title="Unselect all to publish" onClick={() => {
												props.autoCreateProduct.forEach(o => {
													o.active = null // null to disregard this item, false to not publish it, true to publish it
												})
												setRefreshView(refreshView+1)
											}}><FontAwesomeIcon icon={faTimes} style={{fontSize: "14px"}}/></button>
										</div>
										<div className="btn-group btn-group-toggle" data-toggle="buttons">
											<button type="button" className="btn btn-warning ml-3 btn-sm" onClick={() => {
												props.autoCreateProduct.forEach(o => {
													o.removed = true
													o.active = null
												})
												setRefreshView(refreshView+1)
											}}>Select all for removal</button>
											<button className="btn btn-dark btn-sm" data-toggle="tooltip" title="Unselect all for removal" onClick={() => {
												props.autoCreateProduct.forEach(o => {
													o.active = null
													if("removed" in o)
														delete o.removed
												})
												setRefreshView(refreshView+1)
											}}><FontAwesomeIcon icon={faTimes} style={{fontSize: "14px"}}/></button>
										</div>
										<Select
											isClearable
											className="d-inline-block ml-3"
											// menuIsOpen={true}
											styles={{
												control: (base) => ({ ...base, width: 330 }),
												menu: base => ({ ...base, zIndex: 9999 }),
												menuPortal: base => ({ ...base, zIndex: 9999  }),
												menuList: base => ({ ...base, zIndex: 9999, maxHeight: 600 }),
											}}
											onChange={(selectedOption) => {

												if(!selectedOption)	return;

												/* let stInfo = props.storeInfo.find(o => {
													if(o.storeId == parseInt(selectedOption.value))
														return o
												}) */

												filterPerPagination(props.autoCreateProduct).map((o, posAcp)=> {

													return o.variants.map((v, keyVar) => {

														v.storeWanted.some((idStoreWanted, posStoreWanted) => {

															// Remove the elements
															if(idStoreWanted == parseInt(selectedOption.value)){
																/* if("removed" in stInfo){
																	v.storeWanted.push(parseInt(selectedOption.value))
																}else{ */
																	v.storeWanted.splice(posStoreWanted, 1)
																// }
																return true
															}
														})

													})
												})

												// props.setAutoCreateProduct(props.autoCreateProduct.slice(0))

												// Mark the store info As removed
												let storeInfoWanted = null;
												props.storeInfo.find(o => {
													if(o.storeId == parseInt(selectedOption.value)){
														/* if("removed" in o)
															delete o.removed
														else */
														o.removed = true
														storeInfoWanted = {
															value: o.storeId,
															label: `${o.name} (Removed)`
														}
														return true
													}
												})

												props.setSelectedStoreToRemove(storeInfoWanted)
												props.setStoreInfo(props.storeInfo.slice(0))
												// setRefreshView(refreshView+1)
											}}
											options={(() => {
												return props.storeInfo.filter(o => {
													if(o.storeId != refConst.id_store_finale)	return o
												}).map(sObj => {
													return {
														value: sObj.storeId,
														label: `${sObj.name}${"removed" in sObj? " (Removed)" : ""}`
													}
												})
											})()}
											value={props.selectedStoreToRemove? props.selectedStoreToRemove : null}
											placeholder={"Store to remove from current list"}
											isSearchable={true}
											isMulti={false}
										/>
									</span>
								}
							</th>
						</tr>
					</thead>
					<tbody>
						{
							filterPerPagination(props.autoCreateProduct).map((o, posAcp)=> {

								console.info("autoCreateProduct [o]", o);

								/* if(o.raw_sku != "7100259909")	return false
								console.info("TESSSSST", o); */

								let autoC = props.autoCreate.find(a => {
									if(a.id == o.id_auto_creator) return a
								})

								if(!autoC)	return false;
								// if(o.variants.length === 0 ||  o.variants[0].presenceCheckedResult === null)	return false;

								// Example: 7100130665: Visit 3M bCom to configure for price
								if(!("pricingData" in o.api_price_result) || o.api_price_result.pricingData.length === 0){
									o.api_price_result.pricingData = [];
									console.warn("Item with price condition:", o.api_price_result);
									if(o.api_price_result.pricing.length > 0 && o.api_price_result.pricing[0].code == "PRDT-0002"){
										o.discontinued = true
										o.active = false
									}
								}

								console.info('o', o);
								console.info('autoC', autoC);
								console.info('storeInfostoreInfo', props.storeInfo);

								let collectionValue = null,
									datasetValue = null

								if(o.collection_shopify_eio)
									categoriesStoreTo.find(cs => {
										if(cs.last.name == o.collection_shopify_eio){
											collectionValue = {
												value: cs.last.name,
												label: cs.inline
											}
										}
									})

								if(o.dataset_shopify_eio)
									dataSet.find(dt => {
										if(parseInt(dt.id) == o.dataset_shopify_eio){
											datasetValue = {
												value: parseInt(dt.id),
												label: dt.title
											}
										}
									})


								let pricesPerType = {} // Just for faster access to the prices.
								
								o.api_price_result.pricingData.forEach(pd => {
									pricesPerType[pd.unit.name.toLowerCase()] = pd
								})

								if(o.variants.length > 0 && !("selected_saleable_unit" in o.variants[0])){
									o.variants[0].selected_saleable_unit = o.smallest_saleable_unit? o.smallest_saleable_unit : o.default_pricing_type
								}

								/**
								 * Filtered list of price option for the "Add variant list"
								*/

								let listReplacedBy = []
								let listQtyAlreadyIn = o.variants.map(v => {
									if("typeReplacedBy" in v && v.typeReplacedBy.value)
										listReplacedBy.push(v.typeReplacedBy.value)
									return v.selected_saleable_unit
								})

								let listPriceOpAvailable = diff(Object.keys(pricesPerType), listQtyAlreadyIn)
										
								o.api_price_result.pricingData.some((po) => {
									let posFound = listPriceOpAvailable.indexOf(po.unit.name.toLowerCase())
									if(posFound !== -1){
										listPriceOpAvailable[posFound] = po
									}
								})

								/* if(o.sku == "3M-7000122256"){
									console.info("SSSSSSAAAA", listPriceOpAvailable);
									console.info("SSSSSSAAAA2", listQtyAlreadyIn);
								} */
								/****************************************************** */

								/*console.info("listPriceOpAvailable", listPriceOpAvailable);
								console.info("listQtyAlreadyIn", listQtyAlreadyIn);
								console.info("pricesPerType", pricesPerType);
								console.info("o.variant.storeWanted", o.variants.storeWanted); */

								// return
								if(o.variants.length > 0){
									
									console.info("o.variants[0].smallest_saleable_unit", o.variants[0].selected_saleable_unit);
									console.info("o.variantso.variants", o.variants);

									// We need to add the default price only if at least one value is missing from `selling_price`
									let listWithoutPrice = o.variants.filter(v => {
										if(Object.values(v.default_price).filter(val => {
											if(!val)	return val
										}).length > 0 || Object.values(v.default_price).length === 0)	return v
									})

									console.info("listWithoutPrice", listWithoutPrice);
									console.info("minSellingPrice", o.minSellingPrice);
									
									// Prefil the first 
									if(!o.discontinued && listWithoutPrice.length > 0 && (o.variants.length > 0 && o.variants[0].selling_price === null || JSON.stringify(o.variants[0].selling_price) == "{}") && pricesPerType[o.variants[0].selected_saleable_unit])
										updateVariantsDefaultPrices(
											pricesPerType
											, parseFloat(pricesPerType[o.variants[0].selected_saleable_unit].currentPrice.listPrice)
											, parseFloat(pricesPerType[o.variants[0].selected_saleable_unit].currentPrice.listPrice)
											, o.variants[0]
											, o.variants[0].minSellingPrice
											, false
										)
								}

								/** --- Images from 3 sources
								 * For now we need to focus on the xlsx file because these picture are bigger.
								*/
								let feedImgPropName = "additional_pictures" // Contain the list of all images, the first is also the one in "main_image_url"
								let listImg = [],
									imgCount = 0;
								if(o.product_feed_data && o.product_feed_data.files[feedImgPropName]){
									listImg = o.product_feed_data.files[feedImgPropName]
									imgCount = o.product_feed_data.files[feedImgPropName].length
								}
								if(o.api_search_result && o.api_search_result.productSingleImageLinks && o.api_search_result.productSingleImageLinks.length > 0){
									if(o.api_search_result.productSingleImageLinks.length >= imgCount){
										
										o.api_search_result.productSingleImageLinks.forEach((pSIL, pSILPos) => {
											if(listImg.indexOf(pSIL.link) === -1){
												listImg.push(pSIL.link)
												imgCount++;
											}
										})
									}
								}
								// Just in case we have nothing else we can use the application image
								if(o.api_search_result && o.api_search_result.productApplicationImageLinks && o.api_search_result.productApplicationImageLinks.length > 0){
									if(o.api_search_result.productApplicationImageLinks.length >= imgCount){
										
										o.api_search_result.productApplicationImageLinks.forEach((pSIL, pSILPos) => {
											if(listImg.indexOf(pSIL.link) === -1){
												listImg.push(pSIL.link)
												imgCount++;
											}
										})
									}
								}
								if(o.image_urls_added && o.image_urls_added.length > 0){
									o.image_urls_added.forEach((iUA, iUAPos) => {
										listImg.push(iUA)
									})
									imgCount += o.image_urls_added.length
								}

								/* if(o.sku == "3M-7100260774"){
									console.info("sadasdasdasd", o);
								} */

								//* Find the weight
								let feedPricingKey = "packaging_sizes",
									pricingSizes = [],
									weightPerType = []

								if(o.product_feed_data && o.product_feed_data[feedPricingKey]){
									pricingSizes = o.product_feed_data[feedPricingKey]
									for (const shippingType in pricingSizes) {
										if (Object.hasOwnProperty.call(pricingSizes, shippingType)) {
											
											weightPerType[shippingType] = pricingSizes[shippingType].weight? parseFloat(pricingSizes[shippingType].weight.split(" ")[0]) : null
										}
									}
								}

								if(listImg.length === 0){
									listImg.push("https://hausoftools.com/cdn/shop/collections/3m_logo_640x.png?v=1635468350")
								}

								if(o.product_feed_data)
									console.info("imageLinkimageLinkimageLink", o.product_feed_data[feedImgPropName]);
								console.info("imageLinkimageLinkimageLink2222", listImg);
								console.info("pricingSizes", pricingSizes);

								let idStoreToProductInfoPriceProp = {
									[refConst.id_store_finale]: "cost",
									[refConst.id_store_hausfTools]: "haus_price",
									[refConst.id_store_eio]: "eio_price",
									[refConst.id_store_eBay_EIO]: "ebay_price",
									[refConst.id_store_walmart_eio]: "walmart_price",
									[refConst.id_store_amazon_GOTT]: "amazon_us_gott_price",	
									[refConst.id_store_amazon_EIO]: "amazon_us_eio_price",
								}
								
								/* let posImgOngoing = 0;
								imgLoaderWithControl(posImgOngoing, listImg) */

								return <tr key={`${o.id}_ctn_list_autoCreate`} style={{borderBottom: "4px solid orange", boxShadow: "0px 0px 8px orange"}}>
									<td>
										<div className="d-flex flex-row mt-4">
											<div className={
												"img-thumbnail rounded mr-3 d-flex justify-content-center " + 
												(!imgCount > 0? "align-items-center" : "align-items-end")
											} style={{
												backgroundImage: "URL('"+listImg[o.ImgPositionSelected]+"')",
												backgroundPosition: 'center',
												backgroundRepeat: 'no-repeat',
												backgroundSize: 'contain',
												height: "100px",
												width: "100px",
											}}>
												<img src={listImg[o.ImgPositionSelected]} style={{
													display: "none",
													height: "100px",
													width: "100px",
												}} onError={(e) => {
													if(listImg.length > o.ImgPositionSelected && listImg[o.ImgPositionSelected+1]){
														e.target.src = listImg[o.ImgPositionSelected+1];
														o.ImgPositionSelected++;
													}
												}}/>
												{!imgCount? <button className="btn btn-primary" onClick={() => {
													modalAddImg(false, o)
												}}>Add</button> : <span className="smallText bg-light pointer" style={{opacity: 0.8}} onClick={(e) => {
													o.ImgPositionSelected++;
													console.info("imageeee", e);
													$(e.target).parent().css("background-image", "URL('"+listImg[o.ImgPositionSelected]+"')")
													
													// setRefreshView(refreshView+1)
												}}>
													Image count: {imgCount}
												</span>}
											</div>
											<div className="rounded p-1 middeSizeText w-50">
												{
													o.discontinued? <div className={"bg-danger" + " p-1 mb-2 border rounded text-center d-flex align-items-center justify-content-center"}>
														<span className="font-weight-bold pr-3 align-middle">Discontinued Item</span>
													</div> : ""
												}
												<div>
													<span className="font-weight-bold pr-3 d-table-cell align-middle">Remove this Item</span>
													<span className="d-table-cell">
														<input className={'m'} type="checkbox" checked={o.removed? true : false} style={{width: "17px", height: "17px"}} onChange={(e) => {
															console.info('e', e.target.checked);
															o.removed = e.target.checked? true : false
															o.active = e.target.checked? null : true
															setRefreshView(refreshView+1)
														}}/>
													</span>
												</div>
												<div>
													<span className="font-weight-bold pr-3 d-table-cell align-middle">Publish this item</span>
													<span className="d-table-cell">
														<input className={'m'} type="checkbox" checked={o.active? true : false} style={{width: "17px", height: "17px"}} onChange={(e) => {
															console.info('e', e.target.checked);
															o.active = e.target.checked? true : false
															if(e.target.checked)
																delete o.removed
															setRefreshView(refreshView+1)
														}}/>
													</span>
												</div>
												<div>
													<span className="font-weight-bold pr-3 d-table-cell align-middle">EIO Internal ID:</span>
													<span className="d-table-cell">
														{o.id}
													</span>
												</div>
												<div>
													<span className="font-weight-bold pr-3 d-table-cell align-middle">Manufacturer:</span>
													<span className="d-table-cell">
														{autoC.vendor}
														<button type="button" className="btn btn-sm pointer"  data-html="true" data-toggle="tooltip" title="Copy the value in your clipboard"><FontAwesomeIcon className="pointer ml-2" icon={faClipboard} style={{}} onClick={() => {clipboard(`${autoC.vendor}`, props)}}/></button>
													</span>
												</div>
												<div>
													<span className="font-weight-bold pr-3 d-table-cell align-middle">Date added:</span>
													<span className="d-table-cell">
														{moment(o.date).format('YYYY-MM-DD')}
													</span>
												</div>
												<div>
													<span className="font-weight-bold pr-3 d-table-cell align-middle">Sku:</span>
													<span className="d-table-cell">
														{o.sku}
														<button type="button" className="btn btn-sm pointer"  data-html="true" data-toggle="tooltip" title="Copy the value in your clipboard"><FontAwesomeIcon className="pointer ml-2" icon={faClipboard} style={{}} onClick={() => {clipboard(`${o.raw_sku}`, props)}}/></button>
													</span>
												</div>
												<div className="text-wrap">
													<span className="font-weight-bold pr-3 d-table-cell align-middle">Title:</span>
													<span className="d-table-cell">
														{o.product_feed_data && autoC.main_prop_mapping.title && autoC.main_prop_mapping.title in o.product_feed_data? o.product_feed_data[autoC.main_prop_mapping.title] : (o.api_search_result? o.api_search_result.name : "")}
														<button type="button" className="btn btn-sm pointer"  data-html="true" data-toggle="tooltip" title="Copy the value in your clipboard"><FontAwesomeIcon className="pointer ml-2" icon={faClipboard} style={{}} onClick={() => {clipboard(autoC.main_prop_mapping.title? o.product_feed_data[autoC.main_prop_mapping.title] : (o.api_search_result? o.api_search_result.name : ""), props)}}/></button>
													</span>
												</div>
											</div>
										</div>
										<div className="smallText mb-3">
											{/* <div className="d-flex align-items-center p-2">
												<input id={`${o.id}_label_sku`} className={''} type="checkbox" defaultChecked={true} style={{width: "25px", height: "25px"}} onChange={(e) => {
													console.info('e', e.target.checked);
													// o.packQtyToCreate[s.storeId] = true
													props.setAutoCreateProduct(props.autoCreateProduct.slice(0))
												}}/>
												<label htmlFor={`${o.id}_label_sku`} className="m-0 ml-2 noselect">{autoC.prefix + o.product_feed_data[autoC.sku_prop_name]}</label>
											</div> */}
											<div>
												{(() => {
													console.info('o.variants', o.variants);
													
													let varNodeList = o.variants.map((v, keyVar) => {

														let listStoreInfoAlreadyIn = "presenceCheckedResult" in v && Array.isArray(v.presenceCheckedResult)? props.storeInfo.filter(sI => {
															if(v.presenceCheckedResult.indexOf(sI.storeId) !== -1){
																return sI;
															}
														}) : []
						
														console.info("listStoreInfoAlreadyIn", listStoreInfoAlreadyIn);

														let listSelected = [];
														v.storeWanted.forEach(idStoreWanted => {
															if(idStoreWanted)
																listSelected.push({
																	value: idStoreWanted,
																	label: storeListIdToName[idStoreWanted],
																	// background: Math.round(Math.random())? "#13DA25" : "#FF5733",
																	background: "#e18956",
																	color: "#FFFFFF"
																})
														})


														// console.info("listSelectedlistSelected", listSelected);
														// console.info("props.storeInfo", props.storeInfo);
														// console.info("v.storeWanted", v.storeWanted);

														let finalVariantSku = o.sku
														if(o.variants.length > 0 && o.smallest_saleable_unit != v.qty)
															finalVariantSku = <span>{finalVariantSku}<span className="font-weight-bold">_{v.qty}</span></span>


														let weight = o.weight_smallest_unit_grams? o.weight_smallest_unit_grams + " grams" : null
														let UPC = null

														if(o.bcom_sizes && v.selected_saleable_unit){
															o.bcom_sizes.some((bS) => {
																console.info(`${bS.name.toLowerCase()} == ${v.selected_saleable_unit.toLowerCase()}`);
																if(bS.name.toLowerCase() == v.selected_saleable_unit.toLowerCase()){
																	if(bS.dims && "weight" in bS.dims){
																		convertDim(bS.dims)
																		weight = bS.dims.weight.value.toFixed(3) + " " + bS.dims.weight.type
																	}
																	if(bS.upc)
																		UPC = bS.upc
																}else if(v.typeReplacedBy && bS.name.toLowerCase() == v.typeReplacedBy.value){
																	if(bS.dims && "weight" in bS.dims){
																		convertDim(bS.dims)
																		weight = bS.dims.weight.value.toFixed(3) + " " + bS.dims.weight.type
																	}
																	if(bS.upc)
																		UPC = bS.upc
																}
															})
														}

														/* if(o.sku == "3M-7010363303"){
															console.info("pIpIpIpI", JSON.stringify(listStoreInfoAlreadyIn));
														} */

														let costItem = parseFloat(props.getCorrectCostDataFromPricingData(pricesPerType[v.selected_saleable_unit]).netPrice)
														let costItemWithShipping = costItem + (v.rate? parseFloat(v.rate.cost) : 0)

														if(!v.rate){
															console.info("NOooooRattees", o);
														}

														/* if(o.sku == "3M-7100196742"){
															console.info("sadasdasdasdasd", v.rate.cost);
															console.info("sadasdasdsdasd", `(${parseFloat(v.selling_price[202487])} - ${costItemWithShipping}) * 100 / ${costItemWithShipping}`);
															console.info("sadasdasdsdasd2", parseFloat(v.selling_price[202487]));
															console.info("sadasdasdsdasd3", v.rate.cost);
															console.info("sadasdasdsdasd4", props.getCorrectCostDataFromPricingData(pricesPerType[v.selected_saleable_unit]).netPrice);
														} */

														return <div key={`${v.qty}_${o.id}_qty_pack`} className="mt-1 mb-4 border p-3 shadow-sm">
															<span className="font-weight-bold position-relative bg-white p-2" style={{top: "-32px", fontSize: "20px", color: "#757575"}}>
																Variant {keyVar+1}
															</span>
															<div className="d-flex align-items-center ">
																{<Select
																	isClearable
																	styles={refConst.styleList}
																	onChange={(selectedOption) => {

																		if(!selectedOption)	selectedOption = []

																		if(selectedOption.length === 0)
																			v.storeWanted = [refConst.id_store_finale]
																		else{
																			v.storeWanted = selectedOption.map(sO => {
																				return sO.value
																			})
																		}

																		v.storeWanted.sort()

																		setRefreshView(refreshView+1)
																	}}
																	options={(() => {
																		return props.storeInfo.filter(sF => {
																			// return sF;
																			// if(v.isMainSku || sF.storeId != 5)	return sF
																			if(sF.active && v.storeWantedOriginal && v.storeWantedOriginal.indexOf(sF.storeId) != -1)	return sF
																		}).map(sObj => {
																			return {
																				value: sObj.storeId,
																				label: `${sObj.name}`
																			}
																		})
																	})()}
																	value={listSelected}
																	placeholder={"Store To Create"}
																	isSearchable={true}
																	isMulti={true}
																/>}
																<div className="pl-3 pr-1">
																	{
																		listStoreInfoAlreadyIn.map((sIAlreadyIn) => {

																			sIAlreadyIn.db = null
																			refConst.stores.some(s => {
																				if(s.id == sIAlreadyIn.storeId){
																					sIAlreadyIn.db = s
																					return true
																				}
																			})

																			// console.info("sIAlreadyInsIAlreadyIn", sIAlreadyIn);

																			let propPrice = idStoreToProductInfoPriceProp[sIAlreadyIn.storeId]
																			let urlOptions = []
																			let pI = v.presenceInfo

																			if(!pI)	return false

																			if(sIAlreadyIn.storeId == refConst.id_store_eio){
																				urlOptions.push({
																					value: "https://" + sIAlreadyIn.db.account_name + "/admin/products/" + pI.eio_id,
																					label: sIAlreadyIn.db.name + " (Admin)"
																				})
																				urlOptions.push({
																					value: "https://" + sIAlreadyIn.db.account_name + "/products/" + pI.eio_handle,
																					label: sIAlreadyIn.db.name
																				})
																			}
																			if(sIAlreadyIn.storeId == refConst.id_store_hausfTools){
																				urlOptions.push({
																					value: "https://" + sIAlreadyIn.db.account_name + "/admin/products/" + pI.haus_id,
																					label: sIAlreadyIn.db.name + " (Admin)"
																				})
																				urlOptions.push({
																					value: "https://" + sIAlreadyIn.db.account_name + "/products/" + pI.haus_handle,
																					label: sIAlreadyIn.db.name
																				})
																			}
																			if(sIAlreadyIn.storeId == refConst.id_store_eBay_EIO){
																				urlOptions.push({
																					// https://www.ebay.com/sl/list?itemId=255523718975&mode=ReviseItem
																					value: "http://www.ebay.com/sl/list?itemId=" + pI.ebay_id+"&mode=ReviseItem",
																					label: sIAlreadyIn.db.name + " (Admin)"
																				})
																				urlOptions.push({
																					value: pI.ebay_url,
																					label: sIAlreadyIn.db.name
																				})
																			}
														
																			if(sIAlreadyIn.storeId == refConst.id_store_amazon_EIO || sIAlreadyIn.storeId == refConst.id_store_amazon_GOTT){
																				let asin = pI.amazon_us_eio_asin || pI.amazon_us_gott_asin;
																				urlOptions.push({
																					value: `https://sellercentral.amazon.com/abis/listing/edit/offer?marketplaceID=ATVPDKIKX0DER&ref=xx_myiedit_cont_myifba&sku=${encodeURIComponent(pI.sku)}&&asin=${pI.amazon_us_eio_asin || pI.amazon_us_gott_asin}`,
																					label: `${sIAlreadyIn.db.name} (Admin)`
																				})
																				urlOptions.push({
																					value: `https://www.amazon.com/dp/${asin}?ref=myi_title_dp`,
																					label: `${sIAlreadyIn.db.name} Store`
																				})
																			}
														
																			if(sIAlreadyIn.storeId == refConst.id_store_walmart_eio)
																				urlOptions.push({
																					value: pI.walmart_url,
																					label: sIAlreadyIn.db.name
																				})

																			if(sIAlreadyIn.storeId == refConst.id_store_finale)
																				urlOptions.push({
																					value: `https://app.finaleinventory.com/eio/sc2/?product/detail/` + btoa(pI.url_link),
																					label: sIAlreadyIn.db.name
																				})

																			// console.info("urlOptionsurlOptions", urlOptions);

																			let nodeLink = ReactDOMServer.renderToString(urlOptions.map(o => {
																				return <div key={`${o.label}_urlStore`}><a href={o.value} target="_blank" rel="noopener noreferrer">{o.label}</a></div>
																			}))

																			// console.info("nodeLinknodeLink", nodeLink);

																			return <img
																				key={`${sIAlreadyIn.storeId}_listIcon`}
																				src={sIAlreadyIn.img}
																				width="40px"
																				height="40px"
																				className="ml-1 mr-1 pointer"
																				data-content={
																					`<div>
																						<div>exist in ${sIAlreadyIn.name}</div>
																						` + (pI && propPrice? `<div>
																							Price: $${parseFloat(pI[propPrice])}
																							${nodeLink}
																						</div>` : "") + `
																					</div>`
																				}
																				/* data-trigger="hover" */
																				data-toggle="popover"
																				data-html="true"
																			/>
																		})
																	}
																</div>
															</div>
															<div className="d-flex align-items-center mt-3 flex-wrap">
																{
																	props.storeInfo.sort((obj1, obj2) => {
																		if(obj1.pos > obj2.pos)
																			return 1
																		return -1
																	}).filter(sF => {
																		// if(v.isMainSku || sF.storeId != 5)	return sF
																		if(sF.active && v.storeWanted.indexOf(sF.storeId) != -1)	return sF
																	}).map((sObj, posStore) => {

																		/* console.info("sObj.storeIdNew", sObj.storeId);
																		console.info("v.default_priceNew", v.default_price); */

																		if([refConst.id_store_finale].indexOf(sObj.storeId) === -1){

																			let borderClass = v.default_price[sObj.storeId] <= v.selling_price[sObj.storeId]? "" : "border-danger"
																			if(sObj.noPrice)
																				borderClass = ""

																			return <div key={`${sObj.storeId}_${o.id}_manual_price`} className="input-group mr-2 ml-2 mb-3" style={{width: "400px"}}>
																				<div className="input-group-prepend">
																					<span className="input-group-text priceField" id="basic-addon2">$ {sObj.name}</span>
																				</div>
																				<div className="input-group-prepend noselect pointer" onClick={() => {
																					if(sObj.noPrice)
																						return false
																					if(sObj.storeId in v.default_price){
																						v.selling_price[sObj.storeId] = parseFloat(v.default_price[sObj.storeId])
																						$(`#priceField_${sObj.storeId}_${v.id}`).val(parseFloat(v.default_price[sObj.storeId]))
																						setRefreshView(refreshView+1)
																					}
																				}}>
																					<span className="input-group-text priceField">
																						${
																							sObj.storeId in v.default_price? v.default_price[sObj.storeId] : ""
																						}
																					</span>
																				</div>
																				<input type="number" disabled={sObj.noPrice} step="0.01" id={`priceField_${sObj.storeId}_${v.id}`}
																					min={sObj.storeId in v.default_price? v.default_price[sObj.storeId] : undefined}
																					className={"form-control " + borderClass}
																					/* value={sObj.storeId in v.selling_price? parseFloat(v.selling_price[sObj.storeId]) : ""} */
																					placeholder="Sell Price" onChange={e => {
																						// if(sObj.storeId in v.default_price && parseFloat(e.target.value) >= v.default_price[sObj.storeId]){
																						if(sObj.storeId in v.default_price && parseFloat(e.target.value) < v.default_price[sObj.storeId]){
																							$(e.target).addClass("border-danger")
																						}else{
																							$(e.target).removeClass("border-danger")
																						}
																						$(e.target).val(e.target.value)
																						v.selling_price[sObj.storeId] = parseFloat(e.target.value)
																						setRefreshView(refreshView+1)
																						// }
																				}}/>
																				<div className={(v.selling_price[sObj.storeId]? "" : "d-none ") + "input-group-append"} data-html="true" data-toggle="tooltip" title={`Calculated with:<br/>$${v.rate? v.rate.cost : ""} Shipping.<br/>Cost: $${costItem}`}>
																					<span className="input-group-text">
																						{
																							Math.ceil(costItemWithShipping > 0? (parseFloat(v.selling_price[sObj.storeId]) - (
																								costItemWithShipping
																							)) * 100 / costItemWithShipping : 0)
																						}%
																					</span>
																				</div>
																			</div>
																		}
																	})
																}
																{
																	pricesPerType[v.selected_saleable_unit]? 
																		<div className="d-flex flex-column p-2" style={{fontSize: "16px", minWidth: "200px"}}>
																			<div className="d-flex align-items-center"><span style={{minWidth: "120px"}}>3M Cost: </span><span className="font-weight-bold">
																				${costItem}
																				{
																					props.hasFuturePrice(pricesPerType[v.selected_saleable_unit])? 
																						<span className="smallText ml-3">
																							Currently: {pricesPerType[v.selected_saleable_unit].currentPrice.netPrice}, new price starting: {moment(pricesPerType[v.selected_saleable_unit].futurePrice.effectiveDate.date).format("YYYY-MM-DD")}
																						</span>
																					: ""
																				}
																			</span><button type="button" className="btnEnd btn btn-sm pointer m-0 p-0"  data-html="true" data-toggle="tooltip" title="Copy the value in your clipboard"><FontAwesomeIcon className="pointer ml-2" icon={faClipboard} style={{}} onClick={() => {clipboard(props.getCorrectCostDataFromPricingData(pricesPerType[v.selected_saleable_unit]).netPrice, props)}}/></button></div>
																			<div className="d-flex align-items-center"><span style={{minWidth: "120px"}}>3M List Price: </span><span className="font-weight-bold">
																				${props.getCorrectCostDataFromPricingData(pricesPerType[v.selected_saleable_unit]).listPrice}
																			</span><button type="button" className="btnEnd btn btn-sm pointer m-0 p-0"  data-html="true" data-toggle="tooltip" title="Copy the value in your clipboard"><FontAwesomeIcon className="pointer ml-2" icon={faClipboard} style={{}} onClick={() => {clipboard(props.getCorrectCostDataFromPricingData(pricesPerType[v.selected_saleable_unit]).listPrice, props)}}/></button></div>
																			<div className="d-flex align-items-center"><span style={{minWidth: "120px"}}>Weight: </span><span className="font-weight-bold">
																				{weight}
																			</span><button type="button" className="btnEnd btn btn-sm pointer m-0 p-0"  data-html="true" data-toggle="tooltip" title="Copy the value in your clipboard"><FontAwesomeIcon className="pointer ml-2" icon={faClipboard} style={{}} onClick={() => {clipboard(weight.split(" ")[0], props)}}/></button></div>
																			<div className="d-flex align-items-center"><span style={{minWidth: "120px"}}>UPC: </span><span className="font-weight-bold">
																				{UPC}
																			</span><button type="button" className="btnEnd btn btn-sm pointer m-0 p-0"  data-html="true" data-toggle="tooltip" title="Copy the value in your clipboard"><FontAwesomeIcon className="pointer ml-2" icon={faClipboard} style={{}} onClick={() => {clipboard(UPC, props)}}/></button></div>
																			<div className="d-flex align-items-center"><span style={{minWidth: "120px"}}>MOQ: </span><span className="font-weight-bold">
																				<b>{pricesPerType[v.selected_saleable_unit].minOrderQty}</b> of {pricesPerType[v.selected_saleable_unit].conversion} pieces ({pricesPerType[v.selected_saleable_unit].minOrderQty*pricesPerType[v.selected_saleable_unit].conversion})
																			</span></div>
																			<div className="d-flex align-items-center"><span style={{minWidth: "120px"}}>Total Cost: </span><span className="font-weight-bold">
																				${props.getCorrectCostDataFromPricingData(pricesPerType[v.selected_saleable_unit], v).netPrice * (pricesPerType[v.selected_saleable_unit].minOrderQty)}
																			</span></div>
																		</div>
																		: ""
																}
															</div>
															<div className="d-flex align-items-center mt-3">
																<input id={`${keyVar}_label_sku_generated`} className={'ml-3'} type="checkbox" checked={v? v.active : false} style={{width: "25px", height: "25px"}} onChange={(e) => {
																	// console.info('e', e.target.checked);
																	// console.info('packQtyToCreate', v);
																	v.active = e.target.checked? true : false
																	setRefreshView(refreshView+1)
																}}/>
																<label htmlFor={`${keyVar}_label_sku_generated`} className="m-0 ml-2 noselect" style={{fontSize: "16px"}}>
																	{finalVariantSku}
																</label>
																<button type="button" className="ml-2 btn btn-sm btn-dark pointer text-light" data-toggle="tooltip" title="Copy the value in your clipboard">
																	<FontAwesomeIcon className="" icon={faClipboard} style={{}} title={"Copy to clipboard"} onClick={() => {clipboard(finalVariantSku, props)}}/>
																</button>
																<button type="button" className="ml-2 btn btn-danger btn-sm pointer"  data-html="true" data-toggle="tooltip" title="Remove this variant" onClick={() => {
																	o.variants.splice(keyVar, 1)
																	setRefreshView(refreshView+1)
																}}>
																	<FontAwesomeIcon className="" icon={faTrashAlt} />
																</button>
																{
																	weightPerType[v.selected_saleable_unit]? <div className="groupFormModified ml-3">
																		<span>Weight:</span>
																		<input className="noEditable" value={`${weightPerType[v.selected_saleable_unit]} LBS`} disabled/>
																		<button type="button" className="btnEnd btn btn-sm pointer"  data-html="true" data-toggle="tooltip" title="Copy the value in your clipboard"><FontAwesomeIcon className="pointer ml-2" icon={faClipboard} style={{}} onClick={() => {clipboard(weightPerType[v.selected_saleable_unit], props)}}/></button>
																	</div> : ""
																}
																{
																	<div className="d-inline-block border border-info rounded m-2 pl-2 pr-2 pt-2">
																		<div className="mt-0 mb-1 d-flex align-items-center justify-content-between">
																			Shipping Cost
																			<div className="input-group" data-html="true" data-toggle="tooltip" title="Will recalculate the minimum selling price base on this shipping cost">
																				<input className="form-control smallText ml-3" style={{padding: "2px", maxWidth: "90px"}} defaultValue={"shipping_modified" in v && v.shipping_modified? v.shipping_modified : ""} onChange={(e) => {
																					// console.info("eeeeeeeeeee", e.target.value);
																					v.shipping_modified = e.target.value
																				}} placeholder="Manual"/>
																				<div className="input-group-append">
																					<button type="button" className="btn btn-sm btn-info pt-0 pb-0 pl-1 pr-1" onClick={() => {
																						props.loading(true);
																						loadMinSellingPrices([Object.assign({
																							"sku": o.sku,
																							"weight": weight.split(" ")[0],
																							"weight_unit": weight.split(" ")[1],
																							"cost": props.getCorrectCostDataFromPricingData(pricesPerType[v.selected_saleable_unit]).netPrice,
																						}, "shipping_modified" in v && v.shipping_modified? {
																							"shipping_cost": v.shipping_modified
																						} : {})], (r) => {
																							console.info("Fetch loadMinSellingPrices", r);
																							props.loading(false);
																							if(r.success && r.data.length > 0){
																								r.data.some(d => {
																									if(d.sku == o.sku){
																										v.default_price = d.minSellingPrice
																										v.rate = d.rate
																										setRefreshView(refreshView+1)
																										return d
																									}
																								})
																							}
																						})
																					}}>Set</button>
																				</div>
																			</div>
																		</div>
																		<div className="d-flex flex-row align-items-center">
																			<div>
																				{
																					v.shipping_cost? Object.keys(v.shipping_cost).map((side, posSC) => {
																						if(v.shipping_cost[side] && "total" in v.shipping_cost[side])
																						return <div key={`${o.id}_weight_${side}`} className="groupFormModified mb-2">
																							<span>{side}</span>
																							<input className="noEditable" value={`${v.shipping_cost[side].total}`} disabled/>
																						</div>
																					}) : ""
																				}
																			</div>
																			<button type="button" className="ml-2 btn btn-sm btn-info pt-0 pb-0 pl-1 pr-1 mb-2" onClick={() => {
																				props.loading(true);
																				fetchShippingRate({
																					weight: weight.split(" ")[0],
																					weight_unit: weight.split(" ")[1]
																				}, (r) => {
																					props.loading(false);
																					console.info("Fetch fetchShippingRate", r);
																					v.shipping_cost = r.data
																					setRefreshView(refreshView+1)
																				})
																			}}>Reload</button>
																		</div>
																	</div>
																}
																<Select
																	isClearable
																	className="d-inline-block ml-3"
																	/* menuIsOpen={true} */
																	onChange={(selectedOption) => {

																		let newType = o.default_pricing_type
																		if(!selectedOption){
																			delete v.typeReplacedBy
																			newType = o.smallest_saleable_unit

																			v.selected_saleable_unit = o.smallest_saleable_unit
																			// v.qty = o.smallest_saleable_unit
																			/* setRefreshView(refreshView+1)
																			return; */
																		}else{
																			newType = selectedOption.value
																			v.typeReplacedBy = selectedOption

																			v.selected_saleable_unit = v.typeReplacedBy.value
																			// v.qty = v.typeReplacedBy.value
																		}

																		console.info("newTypenewType", newType);

																		let weight = null,
																			weightUnit = null

																		o.bcom_sizes.some(bS => {
																			if(bS.name.toLowerCase() == newType){
																				if(bS.dims && "weight" in bS.dims){
																					convertDim(bS.dims)
																					weight = bS.dims.weight.value.toFixed(3)
																					weightUnit = bS.dims.weight.type
																					return true
																				}
																			}
																		})

																		// console.info("weightweight", weight);
																		// console.info("weightUnitweightUnit", weightUnit);
																		
																		if(weight && weightUnit){

																			loadMinSellingPrices([{
																				"sku": o.sku,
																				"weight": weight,
																				"weight_unit": weightUnit,
																				"cost": props.getCorrectCostDataFromPricingData(pricesPerType[newType]).netPrice,
																			}], (dr) => {
													
																				dr.data.some(d => {
																					if(d.sku == o.sku){
													
																						console.info("min Selling price found and added", d);
																						v.minSellingPrice = d.minSellingPrice
																						v.rate = d.rate
													
																						updateVariantsDefaultPrices(
																							pricesPerType
																							, parseFloat(pricesPerType[newType].currentPrice.listPrice)
																							, parseFloat(pricesPerType[newType].currentPrice.listPrice)
																							, v,
																							v.minSellingPrice,
																							true
																						)
																						return d;
																					}
																				})
																			})
	
																			// props.setAutoCreateProduct(props.autoCreateProduct.slice(0))
																		}
																		setRefreshView(refreshView+1)
																	}}
																	options={(() => {
																		return listPriceOpAvailable.filter(po => {
																			return po.unit.name.toLowerCase() != v.selected_saleable_unit.toLowerCase() && po.unit.name.toLowerCase() != v.qty.toLowerCase() /*  && (!v.typeReplacedBy || po.unit.name.toLowerCase() != v.typeReplacedBy.value) */
																		}).map(po => {
																			return {
																				value: po.unit.name.toLowerCase(),
																				label: `[${po.unit.name}] net: $${parseFloat(props.getCorrectCostDataFromPricingData(po).netPrice).toFixed(2)}, list: $${parseFloat(po.currentPrice.listPrice).toFixed(2)}`
																			}
																		})
																	})()}
																	value={v.typeReplacedBy? v.typeReplacedBy : null}
																	placeholder={"Replace selling type by"}
																	isSearchable={true}
																/>
															</div>
														</div>
													})

													if(listPriceOpAvailable.length > 0)
														varNodeList.push(
															<div key={`${o.id}_section_new_pack`} className="mt-4 border p-3 position-relative">
																
																<div className="position-absolute d-flex align-items-center pr-2" style={{top: "-16px", backgroundColor: "white"}}>
																	<FontAwesomeIcon icon={faPlusSquare} style={{backgroundColor: "white", color: "rgb(212, 212, 212)", fontSize: "25px"}}/>
																	<span className="ml-2 font-weight-bold" style={{fontSize: "20px", color: "#757575"}}>New variant</span>
																</div>

																<div>*Smallest Saleable Unit: <span className="font-weight-bold">{o.smallest_saleable_unit? o.smallest_saleable_unit : "Not defined"}</span></div>
																
																<form onSubmit={(e) => {
																	e.preventDefault()
																	console.info("SSSSSEEEE", e);
																	submitQtyPack(e, o, pricesPerType)
																}} className="d-flex justify-content-center p-3">
																	<div className="">
																		{
																			listPriceOpAvailable.filter(po => {
																			
																				if(listReplacedBy.length === 0 || listReplacedBy.indexOf(po.unit.name.toLowerCase()) === -1)
																					return po
																				
																			}).map(po => {
																				let isSmallestUnit = o.smallest_saleable_unit == po.unit.name.toLowerCase()
																				return <button
																					key={`${po.unit.name}_${o.id}`}
																					className="btn btn-outline-secondary mr-3 p-3 pl-5 pr-5" value={po.unit.name.toLowerCase()} onClick={(e) => {
																						submitQtyPack(e, o, pricesPerType, po.unit.name.toLowerCase())
																				}} style={{
																					border: (isSmallestUnit? "4" : "1") + "px solid" 
																				}}>
																					<div style={{fontSize: "20px", fontWeight: "bold"}}>{isSmallestUnit? "*" : ""}{po.unit.name}</div>
																					<div>MOQ {po.minOrderQty} of {po.conversion} piece{(po.minOrderQty*po.conversion > 1? 's' : '')}</div>
																					<div>Cost ${parseFloat(props.getCorrectCostDataFromPricingData(po).netPrice).toFixed(2)}</div>

																				</button>
																			})
																		}
																	</div>
																</form>
															</div>)
														
													return varNodeList
												})()}
											</div>
										</div>
									</td>
								</tr>
							})
						}
					</tbody>
				</table>
				<div className={(filterValidData().length > 0? "" : "d-none") + " fixed-bottom text-center mb-1"} style={{
					height: "1px"
				}}>
					<button type="button" className="bgEio d-flex align-items-center justify-content-center text-center btn btn-lg btn-block d-inline-block text-white mx-auto position-relative" disabled={props.loading()} style={{width: "50%", opacity: '0.8', bottom: "50px"}} onClick={() => {
						
						let dataToSend = filterValidData()
						let self = props
						console.info('Submit_all_data', dataToSend);
						
						if(dataToSend.length > 0){
							props.setSubmited(true)
							submitAutoCreateComplete(dataToSend, (r) => {
								props.setSubmited(false)
								console.info("Res autoCreate_complete_data", r);
								self.loading(false);
								self.msgAlert(
									r.errors && r.errors.length > 0? <div>{JSON.stringify(r.errors)}</div> : null, 
									r.success? <div>
										<div>{r.resPrd.updated} products updated</div>
										<div>{r.resVar.processed} variants added</div>
									</div> : null, 
								);
								self.reloadData()
							})
						}
					}}>
						<span>Validate 3M  - [{filterValidData().length} Items]</span>
						<img className="ml-4" style={{height: "35px"}}></img>
					</button>
				</div>
			</div>
		</div>

		return <div className="mt-3" style={{
			overflowY: "auto",
		}}>{output}</div>;
	}

	const submitAutoCreateComplete = (data, callback) => {
		
		props.loading(true);
		console.info("Submit_all_data", data);
		console.info("Submit_all_data uid", props.getUser().uid);

		ajaxPerso( {
			"api": "autoCreator",
			"trigger": "autoCreate_complete_data",
			"uid": props.getUser().uid,
			"data": JSON.stringify(data)
		}, callback, (jqXHR, textStatus, errorThrown) => {
			props.loading(false);
			props.info({error: textStatus});
		});
	}

	let modalAddImg = (refresh, autoCreateProductObj) => {

		console.info("autoCreateProductObj", autoCreateProductObj);

		props.modal({
			show: refresh? false : true,
			title: <span>Add a picture <h6 className="text-secondary d-inline ml-3" style={{fontSize: "12px", textShadow: "0px 0px 4px #FFF"}}>v{versionImgAdder}</h6></span>,
			options: {
				// width: "modal-xl"
			},
			html: (popup) => {

				return <div>

					<div>
						<p className="h5">Add an image by URL</p>
						<div className="alert alert-primary" role="alert">
							Idea: you can upload your picture in our Shopify File system and copy/paste the URL in here
						</div>
						<div className="alert alert-primary" role="alert">
							You can add multiple URL separated with a comma.
						</div>
						<form onSubmit={(e) => {
							e.preventDefault()
							console.info("refUrlImgAdder", refUrlImgAdder.current.value);
							let urls = refUrlImgAdder.current.value.split(",").map(url => {
								return url.trim()
							});
							addImgUrlToProduct(
								autoCreateProductObj.id,
								urls,
								(r) => {
									autoCreateProductObj.image_urls_added = urls
									setRefreshView(refreshView+1)
								}
							)
						}}>
							<div className="input-group mb-3">
								<div className="input-group-prepend">
									<span className="input-group-text" id="basic-addon1">Image</span>
								</div>
								<input type="text" className="form-control" placeholder="URL1, URL2..." ref={refUrlImgAdder}/>
								<div className="input-group-append">
									<button className="btn btn-outline-secondary" type="submit">Submit URL</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			}
			, exit: (popup, close) => {
				close()
			}, return: {
				title: "Exit",
				fnc: (popup, close) => {
					close()
				}
			}
		});
	}
		
	return (<div className={("className" in props? props.className : "") + " d-flex flex-row"}>
		{/* <div className="alert alert-primary w-100 text-wrap mt-4" role="alert">
			Create or Update an A+ content, A+ represents a section on the website that highlights a product per its type and brand.
		</div> */}
		<Menu {...props}/>
		{ viewListMeta() }
	</div>);
}

export default ListView;